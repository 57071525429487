import { Dropdown, Modal, Button } from "react-bootstrap";
import { useState, useEffect } from 'react';
import Card from "react-bootstrap/Card";
import { Switch } from '@mui/material';
import "./Vehical.css"
import "./VehicalList.css"
import deleteIcon from "../../../assets/icons/db-icons/delete-icon.svg"
import editIcon from "../../../assets/icons/db-icons/edit-icon.svg"
import { useDispatch, useSelector } from "react-redux";
import { activate_deactivate, delete_vehical } from "../../../context/actions/vehicalAction";
import { useNavigate } from "react-router-dom";

function Vehical({ vehicalList }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(state => state.userReducer.token);

  const [isActive, setIsActive] = useState(() => {
    const storedIsActive = localStorage.getItem('isActive');
    return storedIsActive ? storedIsActive === 'true' : vehicalList?.isActive || true;
  });

  useEffect(() => {
    localStorage.setItem('isActive', isActive);
  }, [isActive]);

  const handleActivationToggle = () => {
    const newIsActive = !isActive;
    setIsActive(newIsActive);
    dispatch(activate_deactivate(token, vehicalList?.type, vehicalList?.id, newIsActive));
  };

  const [showModal, setShowModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // State to track current image index

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % vehicalList?.image?.length || 1); // Increment index and loop back to 0 if exceeds image count
  };

  return (
    <div className="card_wrapper">
      <Card style={{ width: "15rem", marginLeft: "1rem", marginTop: "3rem" }}>
        {/* <Card.Img
          style={{ height: "10rem", cursor: 'pointer' }}
          variant="top"
          src={`${process.env.REACT_APP_IMG_URL}/uploads/${vehicalList?.image?.[currentImageIndex]?.filename}`}
          alt="img"
          onClick={handleShowModal} // Add this onClick handler
        /> */}
        <Card.Img
          style={{ height: "10rem", cursor: 'pointer' }}
          variant="top"
          src={`http://localhost:8000/uploads/${vehicalList?.image?.[currentImageIndex]?.filename}`}
          alt="img"
          onClick={handleShowModal} // Add this onClick handler
        />
       
        <Card.Body>
          <div className="edit_wrapper">
            <div>
              <Card.Title>Details :</Card.Title>
            </div>
            <div className="custom-control material-switch cursor-pointer">
              <Dropdown className="notify">
                <Dropdown.Toggle variant="link" id="dropdown-basic">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-three-dots-vertical"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <label className="custom-control">
                      <Switch
                        checked={isActive}
                        onClick={handleActivationToggle}
                      />
                      {isActive ? "active" : "inactive"}
                    </label>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <span onClick={() => navigate("/admin/dashboard/edit-vehical", { state: vehicalList })}>
                      <img src={editIcon} alt="edit" />
                      edit
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <span onClick={() => dispatch(delete_vehical(token, vehicalList?.type, vehicalList?.id, navigate))}>
                      <img src={deleteIcon} alt="delete" />
                      delete
                    </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <Card.Text>
            <div className="car_details_wrap">
              <p> capacity : {vehicalList?.seating_capacity} </p>
              <p> rate : {vehicalList?.ratePer_km} </p>
              <p> Ac or Non AC : {vehicalList?.ac_or_nonAc}</p>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>

      {/* Modal for displaying full image */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Body>
          <div className='modelHeading'>Image</div>
          <img src={`http://localhost:8000/uploads/${vehicalList?.image?.[currentImageIndex]?.filename}`} alt="Full Image" style={{ width: '100%', height: '100%' }} />
          <div className='modelBotton'>
            <Button className='closeBotton' variant="primary" onClick={handleCloseModal}>Close </Button>
            <Button className='nextBotton' variant="primary" onClick={handleNextImage}>Next</Button> {/* Attach onClick event to handle next image */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Vehical;
