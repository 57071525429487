import React from "react";
import PublicLayout from "../components/layouts/PublicLayout";
// import Home from "../modules/home/Home";
import AdminSignUP from "../modules/authentication/admin/adminSignUp/AdminSignUp";
import AdminSignIn from "../modules/authentication/admin/adminSignIn/AdminSignIn";
import Login from "../modules/authentication/admin/adminSignIn/AdminSignIn";
import LandingPage from "../modules/LandingPage/LandingPage";

const publicRoutes = [
	{
		path: "/",
		exact: true,
		layout: PublicLayout,
		element: <PublicLayout><LandingPage/></PublicLayout> 
	},
	{
		path: "/admin/login",
		exact: true,
		layout: PublicLayout,
		element: <PublicLayout><AdminSignIn/></PublicLayout> 
	},
	{
		path: "/admin/signUp",
		exact: true,
		layout: PublicLayout,
		element: <PublicLayout><AdminSignUP/></PublicLayout>
	},
	
	{
		path: "/login",
		exact: true,
		layout: PublicLayout,
		element: <PublicLayout><Login/></PublicLayout>
	},
	
];
export default publicRoutes;
