/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
// import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const AdminLayout = ({ children }) => {
	let {token, user} = useSelector(state => state.userReducer);
	let navigate = useNavigate();
	useEffect(() => {
		if (!token) {
			navigate("/admin/login");  
		}
	});
	return (
		<>
			{user.role === "VENDOR" ? children : null}
		</> 
	);
};

export default AdminLayout;
