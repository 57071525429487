import { toast } from "react-toastify";
import { handleResponse } from "../../api-client/handleResponse";
import {
  BASE_URL,
  setRequestConfigs,
} from "../../api-client/setRequestConfigs";
import { alertActions } from "../reducers/alertReducer";
import { toast_config } from "../../utils/constants";
import axios from "axios";
import { vehicalAction } from "../reducers/vehicalReducer";

const create_vehical = (token, payload, navigate) => {
  const type = payload.get("type");
  return async (dispatch) => {
    dispatch(alertActions.IsLoading({ data: true }));
    try {
      let response;
      if (type === "car") {
        response = await axios.post(BASE_URL + "/vehical/create-car", payload, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
      } else if (type === "bus") {
        response = await axios.post(BASE_URL + "/vehical/create-bus", payload, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        response = await axios.post(
          BASE_URL + "/vehical/create-other",
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      if (response.data.code === 200) {
        dispatch(alertActions.IsLoading({ data: false }));
        toast.success("Vehicle registered successfully", toast_config);
        // navigate("/admin/vehical-detail")
        navigate("/admin/Dashboard/vehical")
      } else {
        dispatch(alertActions.IsLoading({ data: false }));
        handleResponse(response);
        toast.error("Error in registering vehicle", toast_config);
      }
    } catch (error) {
      console.log('error in add veh---',error);
      dispatch(alertActions.IsLoading({ data: false }));
      handleResponse(error.response?.data);
      toast.error("Error in registering vehicle", toast_config);
    }
  };
};
const save_Vehical_Step = (token, navigate) => {
  return async (dispatch) => {
    dispatch(alertActions.IsLoading({ data: true }));
    const serverRequest = setRequestConfigs(token);
    let response;
    try {
      response = await serverRequest.get("/vehical/save-vehical-step");
      if (response.data.code === 200) {
        dispatch(alertActions.IsLoading({ data: false }));
        toast.success("Vehicle registered successfully", toast_config);
        navigate("/admin/Dashboard/vehical");
      } else {
        dispatch(alertActions.IsLoading({ data: false }));
        handleResponse(response);
        toast.error("Error in saving vehicle", toast_config);
      }
    } catch (error) {
      dispatch(alertActions.IsLoading({ data: false }));
      handleResponse(error.response?.data);
      toast.error("Error in saving vehicle", toast_config);
    }
  };
};
const get_all_vehical = (token) => {
  return async(dispatch) => {
      dispatch(alertActions.IsLoading({ data: true }));
      const serverRequest = setRequestConfigs(token);
      let response;
      try {
        response = await serverRequest.get("/vehical/all-vehical")
        if (response.data.code === 200) {
          dispatch(alertActions.IsLoading({ data: false }));
          dispatch(vehicalAction.allVehical({data : response.data.data}))
        } else {
          dispatch(alertActions.IsLoading({ data: false }));
          toast.error(response.data.message,toast_config)
          handleResponse(response?.data);
        }
      } catch (error) {
        toast.error(error.response?.data?.message,toast_config)
        handleResponse(error.response?.data);
      }
  };
};

const delete_vehical = (token,type,id, navigate) => {
  return async(dispatch) => {
      dispatch(alertActions.IsLoading({ data: true }));
      const serverRequest = setRequestConfigs(token);
      let response;
      try {
        response = await serverRequest.delete(`/vehical/delete-vehical?vehicalType=${type}&vehicalId=${id}`)
        if (response.data.code === 200) {
          dispatch(alertActions.IsLoading({ data: false }));
          navigate("/admin/dashboard/vehical")
        } else {
          dispatch(alertActions.IsLoading({ data: false }));
          toast.error(response.data.message,toast_config)
          handleResponse(response?.data);
        }
      } catch (error) {
        handleResponse(error.response?.data);
        toast.error(error.response?.data?.message,toast_config)
      }
  };
};
const edit_vehical = (token,payload,id, navigate) => {
  const type = payload.get("type");
  return async(dispatch) => {
      dispatch(alertActions.IsLoading({ data: true }));
      let response;
      try {
        response = await axios.put(
          BASE_URL + `/vehical/edit-vehical?vehicalType=${type}&vehicalId=${id}`,
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.code === 200) {
          dispatch(alertActions.IsLoading({ data: false }));
          toast.success(response?.data?.message, toast_config);
          navigate("/admin/dashboard/vehical")
        } else {
          dispatch(alertActions.IsLoading({ data: false }));
          toast.error(response.data.message,toast_config)
          handleResponse(response?.data);
        }
      } catch (error) {
        dispatch(alertActions.IsLoading({ data: false }));
        handleResponse(error.response?.data);
        toast.error(error.response?.data?.message,toast_config) 
      }
  };
};

const delete_vehical_image = (token,type,id,payload) => {
  return async(dispatch) => {
      dispatch(alertActions.IsLoading({ data: true }));
      const serverRequest = setRequestConfigs(token);
      let response;
      try {
        response = await serverRequest.put(`/vehical/delete-vehical-image?vehicalType=${type}&vehicalId=${id}`,payload)
        if (response.data.code === 200) {
          dispatch(alertActions.IsLoading({ data: false }));
        } else {
          dispatch(alertActions.IsLoading({ data: false }));
          toast.error(response.data.message,toast_config)
          handleResponse(response?.data);
        }
      } catch (error) {
        handleResponse(error.response?.data);
        toast.error(error.response?.data?.message,toast_config)
      }
  };
};
const activate_deactivate = (token,type,id) => {
  return async(dispatch) => {
      dispatch(alertActions.IsLoading({ data: true }));
      const serverRequest = setRequestConfigs(token);
      let response;
      try {
        response = await serverRequest.put(`/vehical/activateDeactivate-vehical?vehicalType=${type}&vehicalId=${id}`)
        if (response.data.code === 200) {
          dispatch(alertActions.IsLoading({ data: false }));
          console.log("response in active action vehical",response.data)
        } else {
          dispatch(alertActions.IsLoading({ data: false }));
          toast.error(response.data.message,toast_config)
          handleResponse(response?.data);
        }
      } catch (error) {
        console.log("error in action vehical",error)
        handleResponse(error.response?.data);
        toast.error(error.response?.data?.message,toast_config)
      }
  };
};

const add_new_vehical = (token, payload, navigate) => {
  const type = payload.get("type");
  return async (dispatch) => {
    dispatch(alertActions.IsLoading({ data: true }));
    const serverRequest = setRequestConfigs(token);
    try {
      let response;
      if (type === "car") {
        response = await axios.post(BASE_URL + "/vehical/create-car", payload, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
        console.log('--------response-----------',response)
      } else if (type === "bus") {
        response = await axios.post(BASE_URL + "/vehical/create-bus", payload, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        response = await axios.post(
          BASE_URL + "/vehical/create-other",
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      if (response.data.code === 200) {
        dispatch(alertActions.IsLoading({ data: false }));
        toast.success("Vehicle added successfully", toast_config);
        navigate("/admin/dashboard/vehical")
      } else {
        dispatch(alertActions.IsLoading({ data: false }));
        handleResponse(response);
        toast.error("Error in registering vehicle", toast_config);
      }
    } catch (error) {
      console.log(error)
      dispatch(alertActions.IsLoading({ data: false }));
      handleResponse(error.response?.data);
      toast.error("Error in registering vehicle...", toast_config);
    }
  };
};

export { create_vehical,save_Vehical_Step ,get_all_vehical,delete_vehical,edit_vehical,delete_vehical_image,activate_deactivate,add_new_vehical};
